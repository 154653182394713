import React from "react";
import AppWrapper from "../layouts/AppWrapper";
import ContentWrapper from "../layouts/ContentWrapper";
import Vector from "../assets/icons/vector.svg";
import { TextInput } from "../components/Form";
import { CancelButton } from "../components/Button";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { fetchData } from "../services/fetch";
import { useQuery } from "react-query";
import moment from "moment";
/** Content Body */
const Group = () => {
  const navigate = useNavigate()

  const { id } = useParams()
  const fetchUpdateHistoryDetail = (id) => {

    return fetchData({
      url: `api/update_histories/${id}`
    })
  }
  const { isLoading, refetch, data: response } = useQuery(
    ['get-update-history-detail', id], 
    () => fetchUpdateHistoryDetail(id).then((res) => res.data.update_histories),
    {
      keepPreviousData: true,
      onSuccess: async(response) => {
        response.from_custom_parameter1 = decode(response?.from_custom_parameter1)
        response.from_custom_parameter2 = decode(response?.from_custom_parameter2)
        response.from_custom_parameter3 = decode(response?.from_custom_parameter3)
        // response.custom_parameter1 = {}
        // for (const [key, value] of Object.entries(response.from_custom_parameter1)) {
        //   response.custom_parameter1[key] = [value]
        // }
        // for (const [key, value] of Object.entries(response.to_custom_parameter1)) {
        //   if(response.custom_parameter1[key] && Array.isArray(response.custom_parameter1[key])) {
        //     response.custom_parameter1[key].push(value)
        //   }
          
        // }
      },
      onError: (error) => {
        // storeErrorModal(error.message);
      },
    }
  )
  const decode = (string) => {
    if(string === null) return string
    if(typeof string === 'object') return string
    return JSON.parse(string)
  }
  console.log(response)
  return (
    <AppWrapper bgMask="true">
      <ContentWrapper
        // title={<Title />
        className="content-wider-title"
        title={<div className="mt-1 text-primary">アップデート履歴詳細</div>}
        topSpace={
          <div className="d-flex justify-content-end mb-6-px gap-4">
            <CancelButton
              text="閉じる"
              width="173px"
              height="40px"
              onClick={() => navigate("/update-histories")}
            />
          </div>
        }
        smallMarginRight
      >
        <div
          className="text-white"
          style={{
            margin: "20px 169px 20px 20px",
          }}
        >
          <div>
            <div
              className="d-flex align-items-center"
              style={{
                fontSize: "18px",
              }}
            >
              <div
                style={{
                  width: "429px",
                }}
                className=" d-flex"
              >
                <div
                  style={{
                    width: "132px",
                  }}
                >
                  ペルソナ名
                </div>
                <div style={{flexGrow : 1}} className="text-secondary">
                  {response?.persona_type_name}
                </div>
              </div>
              <div style={{
                width: '290px',
                marginLeft: '65px'
              }} className="d-flex justify-content-between">
                <div style={{
                  
                }}>
                  トークンID
                </div>
                <div style={{
                  
                }} className="font-ethno-light text-secondary">
                  {response?.id}
                </div>
              </div>
            </div>

            <div
              className="d-flex align-items-center mt-30-px"
              style={{
                fontSize: "18px",
                height: "40px",
                marginTop: "10px",
              }}
            >
              <div
                style={{
                  width: "429px",
                }}
                className="d-flex h-100"
              >
                <div
                  style={{
                    width: "132px",
                  }}
                >
                  AURA
                </div>
                <div
                  className="d-flex justify-content-between align-items-center h-100"
                  style={{ flexGrow: 1 }}
                >
                  <div className="d-flex align-items-center gap-4 h-100">
                    <TextInput
                      className="h-40-px"
                      width="180.46px"
                      required
                      inputClass="hide-placeholder smaller-placeholder center-placeholder lighter-placeholder"
                      textboxClass="lesser-opacity bg-secondary"
                      value={response?.from_aura_amount}
                    />
                    <img src={Vector} height="9.06" />
                  </div>
                  <div>
                    <div style={{ display: 'flex', alignItems: 'center', color: '#DCC97E' }}>{response?.to_aura_amount}</div>
                  </div>
                </div>
              </div>
              <div style={{
                width: '290px',
                marginLeft: '65px'
              }} className=" d-flex justify-content-between">
                <div style={{
                  
                }}>
                  アップデート日
                </div>
                <div style={{
                  
                }} className="font-ethno-light text-secondary">
                  {moment(response?.updated_at).format("Y.MM.DD")}
                </div>
              </div>
            </div>
          </div>

          <div style={{ marginTop: '33px', width: '686px' }}>
            <p style={{ fontSize: '18px', paddingBottom: 20 }}>カスタムパラメータ 1</p>
            
              {
                response?.from_custom_parameter1 && response?.from_custom_parameter1.map((p, index) => {
                  return (
                    <div key={p.id} className="d-flex align-items-center mb-4" style={{ fontSize: '17px', height: '40px' }}>
                      <div className="d-flex justify-content-between h-100" style={{width: '429px'}}>
                        <div style={{ width: '90px', display: 'flex', alignItems: 'center' }}>{p.name}</div>
                        <div className="h-100">
                          <TextInput
                          disable={true}
                          className="h-40-px"
                          width="180.46px"
                          required
                          inputClass="hide-placeholder smaller-placeholder center-placeholder lighter-placeholder"
                          textboxClass="lesser-opacity bg-secondary"
                          value={p.value}
                        />
                      </div>
                      <div className="d-flex h-100 justify-content-between" style={{ marginLeft: '19px',flexGrow: 1  }}>
                        <div className="text-secondary d-flex align-items-center gap-4">
                          <span>Unit</span> 
                          <img src={Vector} height='9.06'/>
                        </div>
                        <div style={{ display: 'flex', alignItems: 'center', color: '#DCC97E' }}>{response?.to_custom_parameter1[index]?.value}</div>
                      </div>
                    </div>
                  </div>
                  )
                })
              }
              
            
          </div>

          {/* <div style={{ marginTop: '33px', width: '686px' }}>
            <p style={{ fontSize: '18px', paddingBottom: 20 }}>カスタムパラメータ 2</p>
            
              {
                response?.from_custom_parameter2 && response?.from_custom_parameter2.map((p, index) => {
                  return (
                    <div key={p.id} className="d-flex align-items-center mb-4" style={{ fontSize: '17px', height: '40px' }}>
                      <div className="d-flex justify-content-between h-100" style={{width: '429px'}}>
                        <div style={{ width: '90px', display: 'flex', alignItems: 'center' }}>{p.name}</div>
                        <div className="h-100">
                          <TextInput
                          disable={true}
                          className="h-40-px"
                          width="180.46px"
                          required
                          inputClass="hide-placeholder smaller-placeholder center-placeholder lighter-placeholder"
                          textboxClass="lesser-opacity bg-secondary"
                          value={p.value}
                        />
                      </div>
                      <div className="d-flex h-100 justify-content-between" style={{ marginLeft: '19px',flexGrow: 1  }}>
                        <div className="text-secondary d-flex align-items-center gap-4">
                          <span>Unit</span> 
                          <img src={Vector} height='9.06'/>
                        </div>
                        <div style={{ display: 'flex', alignItems: 'center', color: '#DCC97E' }}>{response?.to_custom_parameter2[index]?.value}</div>
                      </div>
                    </div>
                  </div>
                  )
                })
              }
              
            
          </div>

          <div style={{ marginTop: '33px', width: '686px' }}>
            <p style={{ fontSize: '18px', paddingBottom: 20 }}>カスタムパラメータ 3</p>
            
              {
                response?.from_custom_parameter3 && response?.from_custom_parameter3.map((p, index) => {
                  return (
                    <div key={p.id} className="d-flex align-items-center mb-4" style={{ fontSize: '17px', height: '40px' }}>
                      <div className="d-flex justify-content-between h-100" style={{width: '429px'}}>
                        <div style={{ width: '90px', display: 'flex', alignItems: 'center' }}>{p.name}</div>
                        <div className="h-100">
                          <TextInput
                          disable={true}
                          className="h-40-px"
                          width="180.46px"
                          required
                          inputClass="hide-placeholder smaller-placeholder center-placeholder lighter-placeholder"
                          textboxClass="lesser-opacity bg-secondary"
                          value={p.value}
                        />
                      </div>
                      <div className="d-flex h-100 justify-content-between" style={{ marginLeft: '19px',flexGrow: 1  }}>
                        <div className="text-secondary d-flex align-items-center gap-4">
                          <span>Unit</span> 
                          <img src={Vector} height='9.06'/>
                        </div>
                        <div style={{ display: 'flex', alignItems: 'center', color: '#DCC97E' }}>{response?.to_custom_parameter3[index]?.value}</div>
                      </div>
                    </div>
                  </div>
                  )
                })
              }
              
            
          </div> */}

          <div className="d-flex align-items-center">
            <div style={{ fontSize: "18px" }}>Peerage（爵位）</div>
            <div style={{ color: "#FF996A" }}>
              ペルソナの爵位を選択 Duke（公爵）&gt; Marquess（侯爵）&gt;
              Count（伯爵）&gt; Viscount（子爵）&gt; Baron（男爵）
            </div>
          </div>
          <div>
            <div
              className="d-flex align-items-center gap-4 font-ethno-light"
              style={{ height: "40px", marginTop: "26px", fontSize: "17px" }}
            >
              <TextInput
                className="h-40-px"
                width="307px"
                required
                inputClass="hide-placeholder smaller-placeholder center-placeholder lighter-placeholder"
                textboxClass="lesser-opacity bg-secondary text-secondary"
                value={response?.from_peerage}
              />
              <div className="me-4">
                <img src={Vector} height='9.06'/>
              </div>
              <div style={{color: '#DCC97E'}}>
                {response?.to_peerage}
              </div>
            </div>
          </div>
        </div>
      </ContentWrapper>
    </AppWrapper>
  );
};

/** Title */
// const Title = () => {
//   return <div className="d-flex">{/* Title Content */}</div>;
// };

/** Top Space */
const TopSpace = () => {
  return <></>;
};

export default Group;
