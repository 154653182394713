import AppWrapper from "../../layouts/AppWrapper";
import ContentWrapper from "../../layouts/ContentWrapper";
import UserAvatar from '../../assets/images/user-avatar.svg'
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { fetchData } from "../../services/fetch";
import moment from "moment";
const UserDetail = () => {
    const { id } = useParams()

    const { isLoading, refetch, data: response } = useQuery({
        // This is a unique query key identifier, you can add a variable here to
        // refetch every time that variable changes
        queryKey: ["get-users-detail"],
        // Query Function,  function to be invoked for fetching
        queryFn: () => fetchData({ method: 'post',url: `api/users/detail`,}).then(res => res.data),
        
        // Function to be invoked when the request has failed.
        
      });
      console.log(response)

  return (
    <AppWrapper bgMask={true}>
      <ContentWrapper
        titletopmarign="true"
        title={"ユーザー情報"}
        topSpace={<></>}
        marginLeft="12px"
      >
        <div className="mx-36-px my-10-px">
          <div style={{width: 890.57}}>
            <div className="d-flex justify-content-between text-secondary align-items-center">
                <div>
                    <div className="d-flex items-center align-items-center">
                        <div style={{width: 90}}>
                            <img src={UserAvatar} style={{height: 66, width: 66, borderRadius: '50%'}} />
                        </div>
                        <div className="text-secondary" style={{fontSize: 17}}>
                            {response?.user_name}
                        </div>
                    </div>
                </div>
                <div className="d-flex gap-5">
                    <div className="text-white font-notosans-jp" style={{fontSize: 17}}>ユーザー登録日</div>
                    <div className="font-ethno-light" style={{fontSize: 16}}>2024.07.03</div>
                </div>
            </div>
            <div className="d-flex mt-3 align-items-center">
                <div className="text-white" style={{width: 90, fontSize: 18}}>Address</div>
                <div className="text-secondary text-break" style={{fontSize: 16, width: 333}}>{response?.wallet_address}</div>
            </div>
            <div className="mt-4">
                <div className="text-white font-notosans-jp" style={{fontSize: 16}}>トランザクション</div>
                <div className="ms-4">
                    <span className="text-white" style={{fontSize: 16}}>購入金額</span> <span className="text-highlight font-ethno-light ms-1" style={{fontSize: 19}}> / 12,399,910</span> <span className="text-white font-ethno-light" style={{fontSize: 14}}>JPY</span> <button className="bg-btn-primary-color text-white px-3 ms-2" style={{border: 'none', fontSize: 13, borderRadius: 10}}>詳細</button>
                </div>
            </div>
            <div className="mt-4">
                <div className="text-white font-notosans-jp" style={{fontSize: 18}}>ステータス</div>
                <div className="ms-4">
                    <div>
                        <span className="text-white" style={{fontSize: 16}}>ペルソナ保有中</span> <span className="text-highlight font-ethno-light ms-1" style={{fontSize: 19}}> / {response?.have_persona_count}</span>
                    </div>
                    <div className="d-flex mt-2">
                        <div className="text-white" style={{width: 163, fontSize: 16}}>
                            売りオファーの総額
                        </div>
                        <div style={{width: 190}} className="d-flex justify-content-end">
                            <span className="text-highlight font-ethno-light" style={{fontSize: 16}}>{response?.current_sell_offer_sum_yen}</span> <span className="font-ethno-light text-white ms-1" style={{fontSize: 14}}>JPY</span>
                        </div>
                        <div style={{width: 190}} className="d-flex justify-content-end">
                            <span className="text-highlight font-ethno-light" style={{fontSize: 16}}>{response?.current_sell_offer_sum_anima}</span> <span className="font-ethno-light text-white ms-1" style={{fontSize: 14}}>ANM</span>
                        </div>
                    </div>
                    <div className="d-flex mt-2">
                        <div className="text-white" style={{width: 163, fontSize: 16}}>
                            買いオファーの総額
                        </div>
                        <div style={{width: 190}} className="d-flex justify-content-end">
                            <span className="text-highlight font-ethno-light" style={{fontSize: 16}}>{response?.current_buy_offer_sum_yen}</span> <span className="font-ethno-light text-white ms-1" style={{fontSize: 14}}>JPY</span>
                        </div>
                        <div style={{width: 190}} className="d-flex justify-content-end">
                            <span className="text-highlight font-ethno-light" style={{fontSize: 16}}>{response?.current_buy_offer_sum_anima}</span> <span className="font-ethno-light text-white ms-1" style={{fontSize: 14}}>ANM</span>
                        </div>
                    </div>
                </div>
                <div>

                </div>
            </div>
          </div>
        </div>
      </ContentWrapper>
    </AppWrapper>
  );
};

export default UserDetail;
