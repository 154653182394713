import React, { createContext, useEffect, useMemo, useState } from "react";
import { getValue, setValue } from "../services/storage";
import {
  onSetLanguageToEnglish,
  onSetLanguageToJapanese,
  strings,
  translations,
} from "../services/localization";

export const AppContext = createContext("");

export const AppProvider = ({ children }) => {
  const translation = translations;
  const content = translations.menu;
  //Error Dialog
  const [errorModal, setErrorModal] = useState();
  const [errorStatus, setErrorStatus] = useState();
  const [language, setLanguage] = useState();

  const [unexpectedErrorModal, setUnexpectedErrorModal] = useState();
  // Menu List
  const [menus, setMenus] = useState([
    {
      link: "/",
      text: "dashboard",
      is_selected: false,
    },
    {
      link: "/titles",
      text: "title",
      is_selected: false,
    },
    {
      link: "/personas",
      text: "persona",
      is_selected: false,
    },
    
    {
      link: "/update-histories",
      text: "update_history",
      is_selected: false,
    },
    {
      link: "/marketplace",
      text: "marketplace",
      is_selected: false,
    },
    {
      link: "/users",
      text: "user_management",
      is_selected: false,
    },
  ]);

  //if change localstorage => erroModal true and => show Error Dialog
  useEffect(() => {
    // Respond to the `storage` event
    loadLangTimeZone();
    function storageEventHandler(event) {
      // alert(localStorage.getItem("errorModal"));
      setErrorModal(localStorage.getItem("errorModal"));
      setErrorStatus(localStorage.getItem("errorStatus"));
    }

    // Hook up the event handler
    window.addEventListener("storage", storageEventHandler);
    return () => {
      // Remove the handler when the component unmounts
      window.removeEventListener("storage", storageEventHandler);
    };
  }, []);

  const loadLangTimeZone = async () => {
    const language = await getValue("language");
    if (language) {
      setLanguage(language);
    } else {
      setLanguage("Japanese");
    }
  };
  const updateLang = (lang) => {
    setLanguage(lang);
  };

  useEffect(() => {
    if (language === "English") {
      setValue("language", "English");
      onSetLanguageToEnglish();
    } else if (language === "Japanese") {
      setValue("language", "Japanese");
      onSetLanguageToJapanese();
    }
  }, [language]);

  const getLang = () => {
    if (/^en\b/.test(navigator.language)) {
      const deviceLanguage = window.navigator.language;
      if (
        deviceLanguage === "en-us" ||
        deviceLanguage === "en-US" ||
        deviceLanguage === "en"
      ) {
        setLanguage("English");
      } else {
        setLanguage("English");
      }
    } else if (/^ja\b/.test(navigator.language)) {
      const deviceLanguage = window.navigator.language;
      if (deviceLanguage === "ja") {
        setLanguage("Japanese");
      } else {
        setLanguage("English");
      }
    }
  };

  const value = useMemo(
    () => ({
      menus,
      setMenus,
      errorModal,
      errorStatus,
      setErrorModal,
      unexpectedErrorModal,
      setUnexpectedErrorModal,
      updateLang,
      getLang,
      language
    }),
    //eslint-disable-next-line
    [menus, errorModal, unexpectedErrorModal]
  );
  return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
};
