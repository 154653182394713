import React, { useRef, useState } from "react";
import AppWrapper from "../layouts/AppWrapper";
import ContentWrapper from "../layouts/ContentWrapper";
import { SearchInput } from "../components/Form";
import { useNavigate } from "react-router-dom";
import Pagination from "../components/common/Pagination";
import { DayPicker } from "react-day-picker";
import { usePopper } from "react-popper";
import "react-day-picker/dist/style.css";
import "../assets/scss/Calender.css";
import { ReactComponent as LineIcon } from "../assets/icons/lines.svg";
import moment from "moment";
import { fetchData } from "../services/fetch";
import { useQuery } from "react-query";


/** Content Body */
const UpdateHistory = () => {
  const navigate = useNavigate();
  const [params, setParams] = useState({
    page: 1,
    filter: ''
  })
  const [paginateData, setPaginateData] = useState({})
  const handleDetailClick = (id) => {
    navigate(`/update-histories/${id}`);
  };

  const fetchUpdateHistories = () => {
    return fetchData({
      url: 'api/update_histories',
      params
    })
  }

  const filterHistory = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      setParams({...params, page: 1})
      refetch()
    }
  }

  const { isLoading, refetch, data: response } = useQuery(
    ['get-update-histories', params.page], 
    () => fetchUpdateHistories().then((res) => res.data),
    {
      keepPreviousData: true,
      onSuccess: async (response) => {
        const meta = response.update_histories.meta;
        let p = {}
        p.total_count = meta.total;
        p.page_count = meta.last_page;
        if (meta.current_page * 10 > meta.total) {
          p.current_page_amount = meta.total;
        } else {
          p.current_page_amount = meta.current_page * 10;
        }
        p.current_page = meta.current_page;
        setPaginateData(p)
        return response.data
      },
      onError: (error) => {
        // storeErrorModal(error.message);
      },
    }
  )

  const handlePagination = (paginateData) => {
    setPaginateData(paginateData);
    setParams({...params, page: paginateData.current_page})
  };

  return (
    <AppWrapper bgMask="true">
      <ContentWrapper
        className="update-history-content"
        // title={<Title />
        title={""}
        topSpace={<div className="text-white px-2 pb-5-px d-flex justify-content-between h-100">
        {/* Search Box */}
        <SearchInput
          placeholder="ペルソナ名やトークンIDで検索"
          width="551.52px"
          required
          onChange={e => setParams({...params, filter: e.target.value})}
          onKeyDown={filterHistory}
        />
      </div>}
        bottomSpace={<Pagination onChange={handlePagination} paginate_data={paginateData} />}
      >
        <div className="row h-100">
          <div className="table-responsive">
            <table className="w-100" style={{maxHeight: '80%'}}>
              <thead>
                <tr className="grid-table-header text-secondary text-center font-hiragino-sans header-font table-row" style={{fontSize: 17}}>
                  <th
                    className="fw-normal"
                    style={{ width: 150}}
                  >
                    ペルソナNo
                  </th>
                  <th
                    className="fw-normal text-start"
                    style={{ width: 187, paddingLeft: "2rem" }}
                  >
                    ペルソナ名
                  </th>
                  <th
                    className="fw-normal text-start"
                    style={{ width: "14.87%", paddingLeft: "2rem" }}
                  >
                    タイトル名
                  </th>
                  {/*<th className="fw-normal" style={{ width: 200 }}>*/}
                  {/*  ステータス*/}
                  {/*</th>*/}
                  <th
                    className="fw-normal text-end"
                    style={{ width: "19.87%" }}
                  >
                    公開数／ペルソナ数
                  </th>
                  <th
                    className="fw-normal text-end"
                    style={{ width: "12.12%"}}
                  >
                    価格 / JPY
                  </th>
                  <th className="fw-normal text-end pe-5">アップデート日</th>
                  {/* <th style={{ width: 80 }}></th> */}
                </tr>
              </thead>
              <tbody className="text-white body-font overflow-auto hide-scroll" style={{maxHeight: '80%'}}>
                {response?.update_histories?.data?.map((history, i) => (
                  <tr className="mb-3 table-row" style={{ height: 43 }} key={i}>
                    <td className="col text font-ethno-light text-start" style={{fontSize: 14, paddingLeft: 31}}>{history.id}</td>
                    <td className="col text text-start ps-4 font-hiragino-sans pt-1" style={{fontSize: 15}}>{history.persona_type_name}</td>
                    <td className="col text text-start ps-4 font-hiragino-sans pt-1" style={{fontSize: 15}}>{history.group_name}</td>
                    <td className="col text font-ethno-light text-end" style={{fontSize: 14}}>{history.publish_persona_count}/{history.persona_qty}</td>
                    {/*<td className="col text">販売中</td>*/}
                    <td className="col text font-ethno-light text-end" style={{fontSize: 13}}>{history.jpy_amount}</td>
                    <td onClick={() => handleDetailClick(history.id)} className="text-end px-4">
                      <div className="d-inline-flex justify-content-center align-items-center tr-btn gap-2 font-ethno-light">
                        <span style={{fontSize: 13}}>{history.updated_at}</span>
                        <LineIcon className="mt-1"/>
                        {/* <img className="tr-icon" src={LineIcon}/> */}
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </ContentWrapper>
    </AppWrapper>
  );
};

export default UpdateHistory;
