import { React, useContext, useEffect, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import AppWrapper from "../../layouts/AppWrapper";
import ContentWrapper from "../../layouts/ContentWrapper";
import { SearchInput } from "../../components/Form";
import { PenIcon } from "../../components/common/Icon";
import Pagination from "../../components/common/Pagination";
import { fetchData } from "../../services/fetch";
import { useQuery } from "react-query";
import { getValue, storeErrorModal } from "../../services/storage";
import { strings, translations } from "../../services/localization";
import UserIcon from "../../assets/icons/user-white.svg";
import moment from "moment";

/** Content Body */
const Users = () => {
  
  const content = translations.users;
  const [paginatedata, setPaginatedata] = useState([]);
  const [filter, setFilter] = useState();
  const [managements, setManagements] = useState([]);
  const [users, setUsers] = useState([]);
  const [params , setParams] = useState({
    page: 1,
    limit: 10
  })

  // Migrate to react-query
  const { isLoading, refetch } = useQuery({
    // This is a unique query key identifier, you can add a variable here to
    // refetch every time that variable changes
    queryKey: ["get-users-list"],
    // Query Function,  function to be invoked for fetching
    queryFn: () => fetchData({ method: 'post',url: `api/users`, data: {
      wallet_address: "0xA64D463403116B5c96f82fF76854221f1800053b",
      user_wallet_address: "0xA64D463403116B5c96f82fF76854221f1800053b",
      ...params
    } }).then(res => res.data),
    // Function to be excuted when the request is successful
    onSuccess: (response) => {
      let paginateData = {};
      paginateData.total_count = response.total
      paginateData.current_page = response.current_page
      paginateData.page_count = Math.round(response.total / response.per_page) 
      paginateData.current_page_amount = ((response.current_page - 1) * (response.per_page ) + response.data.length)
      console.log(response.data)
      setPaginatedata(paginateData)
      setUsers(response.data);
    },
    // Function to be invoked when the request has failed.
    onError: (error) => {
      storeErrorModal(error.message);
    },
  });
  const handlepagenateChange = (paginateData) => {
    setParams({...params, page: paginateData.current_page})
    refetch()
  };
  const enterSearch = async (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      refetch();
    }
  };
  const language = JSON.parse(localStorage.getItem("language"));

  console.log(language)
  return (
    <AppWrapper bgMask="true">
      <ContentWrapper
        className="users-content"
        title={<div 
          style={{ marginLeft: "5%", marginTop: "2%", fontSize: language === 'Japanese'? 29 : 23 }}>
            {content.title}
          </div>}
        topSpace={<TopSpace onSearch={enterSearch} filter={filter} setFilter={setFilter} placeholdersearch={strings.formatString("ユーザー名やウォレットアドレスで検索")}  />}
        bottomSpace={<Pagination
          onChange={handlepagenateChange}
          paginate_data={paginatedata}
        />}
        smallMarginRight
      >
        {/* Table */}
        <div className="row h-100">
          <div className="table-responsive hide-scroll">
            <table className="w-100" style={{ marginTop: "0.5%" }}>
              <thead className="text-secondary text-center font-hiragino-sans header-font" style={{ height: 50, fontSize: 17, letterSpacing: 0 }}>
                <tr className="grid-table-header table-row">
                    <th className="fw-normal text-start" style={{ width: '28%' }}>
                        {content.user_name_number}
                    </th>
                    <th className="fw-normal text-start" style={{ width: '19%' }}>
                        {content.wallet_addres}
                    </th>
                    <th
                    className="fw-normal text-end"
                    style={{ width: '18%' }}
                    >
                    {content.number_of_hold_personas}
                    </th>
                    <th
                    className="fw-normal text-end"
                    style={{ width: '16%', paddingRight: "2%" }}
                    >
                    {content.amount_in_jp}
                    </th>
                    <th
                    className="fw-normal text-center"
                    style={{ width: '19%' }}
                    >
                    {content.registration_date}
                    </th>
                </tr>
              </thead>
              {/*End Header */}
              {/* Body */}
              <tbody className="text-white body-font text-center font-hiragino-sans overflow-auto hide-scroll h-90">
                {/* Test Data Loop */}
                {users.map((user, i) => (
                  <tr
                    className="mb-3 table-row grid-table-body"
                    key={i}
                    style={{ marginBottom: "0.3%" }}
                  >
                    <td className="col text-start" >
                        <div className="d-inline-flex justify-content-around text" style={{width: 200, fontSize: 17}}>
                            <span className="font-ethno-light" style={{fontSize: 14}}>{user.user_id}</span>
                            <span>／</span>
                            <span style={{fontSize: 15}}>{user.user_name}</span>
                        </div>
                    </td>
                    <td className="col text text-start text-break" style={{fontSize: 11}} >{user.wallet_address}</td>
                    <td className="col text text-end font-ethno-light" style={{ fontSize: 14 }}>
                      {user.persona_count}
                    </td>
                    <td className="col text text-end font-ethno-light" style={{ paddingRight: "2%", fontSize: 13 }}>
                      {user.persona_primary_offer_value_sum}
                    </td>
                    <td
                      style={{  paddingRight: "1.5%" }}
                    >
                     <Link to={`/users/${user.user_id}`}>
                      <div className="d-inline-flex justify-content-center align-items-center tr-btn gap-2 font-ethno-light text-white" style={{ fontSize: 13}}>
                        <span>{moment(user?.user_created_at).format('YYYY.MM.DD')}</span>
                        <img src={UserIcon} style={{width: 11.24, height: 13.55}} />
                      </div>
                     </Link>
                    </td>
                  </tr>
                ))}
                {/*End Test Data Loop */}
              </tbody>
            </table>
          </div>
          {/* End Body */}
        </div>
      </ContentWrapper>
    </AppWrapper>
  );
};

/** Top Space */
const TopSpace = (props) =>  {
  const { setFilter, onSearch ,placeholdersearch ,create} = props;
  return (
    <div className="text-white px-2 pb-5-px h-100">
      {/* Search Box */}
      <SearchInput
        placeholder={placeholdersearch}
        onKeyDown={onSearch}
        className="h-100"
        required
        width="551.52px"
        onChange={(e) => setFilter(e.target.value)}
      />
    </div>
  );
};

export default Users;
