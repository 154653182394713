import AppWrapper from "../../layouts/AppWrapper";
import { useState } from "react";
import { Outlet } from "react-router-dom";

const PersonasContainer = () => {
  const [hideSideBar, setHideSideBar] = useState(false);

  return (
    <AppWrapper bgMask={true} isAnimateSideBar hideSideBar={hideSideBar}>
      <Outlet context={[hideSideBar, setHideSideBar]} />
    </AppWrapper>
  );
};

export default PersonasContainer;
