import ContentWrapper from "../../layouts/ContentWrapper";
import { strings, translations } from "../../services/localization";
import Pagination from "../../components/common/Pagination";
import { useEffect, useState } from "react";
import GridTableHeader from "../../components/common/table/GridTableHeader";
import { EditIcon } from "../../assets/icons";
import { Link, useNavigate, useOutletContext } from "react-router-dom";
import { SearchInput } from "../../components/Form";
import Button from "../../components/Button";
import { fetchData } from "../../services/fetch";
import { storeErrorModal } from "../../services/storage";
import { useQuery } from "react-query";

// const data = [
//   {
//     no: "1200020",
//     name: "Chappy Happy ONE",
//     type_name: "Neco Derby",
//     published: "0 / 5,500",
//     price: "5,000",
//   },
//   {
//     no: "0900014",
//     name: "新人 太郎（しんじん たしんじんたしん)",
//     type_name: "政治党（本部）",
//     published: "1,000,000 / 1,050,001",
//     price: "20,000",
//   },
//   {
//     no: "1200020",
//     name: "Chappy Happy ONE",
//     type_name: "ANICANA WANDER",
//     published: "0 / 5,500",
//     price: "5,000",
//   },
//   {
//     no: "0900014",
//     name: "新人 太郎（しんじん たしんじんたしん)",
//     type_name: "政治党（本部）",
//     published: "1,000,000 / 1,050,001",
//     price: "20,000",
//   },
//   {
//     no: "1200020",
//     name: "Chappy Happy ONE",
//     type_name: "Neco Derby",
//     published: "0 / 5,500",
//     price: "5,000",
//   },
//   {
//     no: "0900014",
//     name: "新人 太郎（しんじん たしんじんたしん)",
//     type_name: "政治党（本部）",
//     published: "1,000,000 / 1,050,001",
//     price: "20,000",
//   },
//   {
//     no: "1200020",
//     name: "Chappy Happy ONE",
//     type_name: "Neco Derby",
//     published: "0 / 5,500",
//     price: "100,000",
//   },
//   {
//     no: "0900014",
//     name: "新人 太郎（しんじん たしんじんたしん)",
//     type_name: "政治党（本部）",
//     published: "1,000,000 / 1,050,001",
//     price: "20,000",
//   },
//   {
//     no: "1200020",
//     name: "Chappy Happy ONE",
//     type_name: "Neco Derby",
//     published: "0 / 5,500",
//     price: "5,000",
//   },
//   {
//     no: "0900014",
//     name: "新人 太郎（しんじん たしんじんたしん)",
//     type_name: "政治党（本部）",
//     published: "1,000,000 / 1,050,001",
//     price: "20,000",
//   },
// ];

const Personas = () => {
  const contents = translations.personas;
  const navigate = useNavigate();
  const [hideSideBar, setHideSideBar] = useOutletContext();

  const [filter, setFilter] = useState("");
  const [paginatedata, setPaginatedata] = useState({
    total_count: 0,
    page_count: 0,
    current_page_amount: 0,
    current_page: 1,
  });
  const [page, setPage] = useState(1);

  useEffect(() => {
    setHideSideBar(false);
  }, []);

  const fetchPersonas = (page = 0) =>
    fetchData({
      url: filter
        ? "api/persona_type?filter=" + filter
        : "api/persona_type?page=" + page,
    });

  const {
    isLoading,
    refetch,
    data: response,
  } = useQuery(["get-personas", page], () => fetchPersonas(page), {
    keepPreviousData: true,
    onSuccess: async (response) => {
      const meta = response.data.persona_types.meta;
      paginatedata.total_count = meta.total;
      paginatedata.page_count = meta.last_page;
      if (meta.current_page * 10 > meta.total) {
        paginatedata.current_page_amount = meta.total;
      } else {
        paginatedata.current_page_amount = meta.current_page * 10;
      }
      paginatedata.current_page = meta.current_page;
    },
    onError: (error) => {
      storeErrorModal(error.message);
    },
  });

  const enterSearch = async (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      refetch();
    }
  };

  const handlePagination = (paginateData) => {
    setPaginatedata(paginateData);
    setPage(paginateData.current_page);
  };

  const tableHeaderItem = [
    {
      headerName: strings.formatString(contents.no),
      width: "12%",
      className: "text-start",
    },
    {
      headerName: strings.formatString(contents.name),
      width: "20%",
    },
    {
      headerName: strings.formatString(contents.type_name),
      width: "18%",
    },
    {
      headerName: strings.formatString(contents.published),
      width: "20%",
      className: "text-end",
    },
    {
      headerName: strings.formatString(contents.price),
      width: "13%",
      className: "text-end",
    },
    {
      headerName: strings.formatString(contents.action),
      width: "17%",
      className: "text-end",
    },
  ];

  if (isLoading) {
    return <em>Loading...</em>;
  }

  return (
    <ContentWrapper
      titletopmarign="true"
      title={strings.formatString(contents.title)}
      topSpace={
        <div className="text-white pb-5-px d-flex justify-content-between h-100">
          {/* Search Box */}
          {/* <form className="text-white px-2 pb-5-px d-flex justify-content-between h-100"> */}
          <div onKeyDown={enterSearch}>
            <SearchInput
              placeholder={strings.formatString(contents.placeholdersearch)}
              // className="w-600-px"
              width="551.52px"
              height="100%"
              onChange={(e) => setFilter(e.target.value)}
              required
            />
          </div>
          {/* </form> */}
          <Button
            text={strings.formatString(contents.button)}
            width="173px"
            onClick={() => {
              navigate("select");
            }}
          />
        </div>
      }
      bottomSpace={
        <Pagination onChange={handlePagination} paginate_data={paginatedata} />
      }
      noOverFlow="ture"
      marginLeft="12px"
    >
      <div className="h-100 w-100">
        <div className="table-responsive hide-scroll custom-table w-100">
          <table>
            <GridTableHeader tableHeaderItem={tableHeaderItem} />
            <tbody className="text-white body-font overflow-auto hide-scroll h-80 text-center mt-1">
              {response.data?.persona_types?.data.map((row, i) => (
                <tr key={i}>
                  <td className="font-ethno-light fs-14-px text-start">
                    {row.persona_type_no}
                  </td>
                  <td className="fs-15-px text-start">
                    {row.persona_type_name}
                  </td>
                  <td className="fs-15-px text-start">{row.group.name}</td>
                  <td className="font-ethno-light fs-14-px text-end">
                    {row.publish_count} / {row.persona_qty}
                  </td>
                  <td className="font-ethno-light fs-14-px text-end">
                    {row.jpy_amount}
                  </td>
                  <td className="font-abj-choki fs-13-px text-end d-flex justify-content-end align-items-center">
                    <Link
                      to={`/personas/${row.id}`}
                      className="action-btn d-flex justify-content-end align-items-center font-gkktt"
                    >
                      <EditIcon />
                      <span>{contents.detail}</span>
                    </Link>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </ContentWrapper>
  );
};

export default Personas;
