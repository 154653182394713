import AppWrapper from "../../layouts/AppWrapper";
import ContentWrapper from "../../layouts/ContentWrapper";
import { strings, translations } from "../../services/localization";
import { useNavigate, useParams } from "react-router-dom";
import Button, { CancelButton } from "../../components/Button";
import { SelectInput, TextInput } from "../../components/Form";
import {
  ArrowIcon,
  CopyIcon,
  DecreaseIcon,
  IncreaseIcon,
  DeleteIcon,
} from "../../assets/icons";
import { InfoModal, LoadingModal } from "../../components/Modal";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useMutation, useQuery } from "react-query";
import { fetchData } from "../../services/fetch";
import { storeErrorModal } from "../../services/storage";
import { setSelectPersona } from "../../slices/personaReducer";

const PersonaUp = () => {
  const contents = translations.personaUp;
  const navigate = useNavigate();
  const { id } = useParams();
  const dispatch = useDispatch();
  const { selectedPersona } = useSelector((state) => state.personas);

  const [showCopyModal, setShowCopyModal] = useState(false);
  const [showLoadingModal, setShowLoadingModal] = useState(false);
  const [progress, setProgress] = useState(0);
  const [values, setValues] = useState({
    aura_amount: 0,
    peerage: "",
  });
  const [customParameter2, setCustomParameter2] = useState({
    0: "",
    1: "",
    2: "",
    3: "",
    4: "",
    5: "",
  });
  const [customParameter3, setCustomParameter3] = useState({
    0: "",
    1: "",
    2: "",
    3: "",
    4: "",
    5: "",
  });
  // const [customParameter1, setCustomParameter1] = useState();
  const [parameters1, setParameters1] = useState();
  const [isLoadPersona, setIsLoadPersona] = useState(false);

  const fetchPersona = (id) =>
    fetchData({
      url: `api/persona_type/${id}`,
    });

  const { isLoading: personaLoading } = useQuery(
    ["get-persona", id],
    () => fetchPersona(id),
    {
      onError: (error) => {
        storeErrorModal(error.message);
        if (error.response.status === 404) {
          navigate("/personas");
        }
      },
      onSuccess: (data) => {
        dispatch(setSelectPersona(data.data.persona_type));
      },
      enabled: isLoadPersona,
    }
  );

  const putPersona = async (data) => {
    const response = await fetchData({
      url: `api/persona_type/${selectedPersona.id}`,
      method: "put",
      data: data,
    });
    return response;
  };

  const { mutate: updatePersona, isLoading } = useMutation(
    async (data) => putPersona(data),
    {
      onError: async (err) => {
        if (err.response.data.errors) {
          console.log(err.response.data.errors);
        }
        storeErrorModal(err);
      },
      onSuccess: async (data) => {
        setProgress(80);
        // setValues({
        //   aura_amount: 0,
        //   peerage: "",
        //   custom_parameter1: {},
        // });
        setProgress(100);
      },
    }
  );

  useEffect(() => {
    if (!selectedPersona) {
      setIsLoadPersona(true);
    }
    if (selectedPersona) {
      // let parameters = {};
      if (selectedPersona.custom_parameter1.length > 0) {
        // Object.keys(selectedPersona.custom_parameter1).forEach((l) => {
        //   parameters[l] = "";
        // });
        // setValues({ ...values, custom_parameter1: parameters });
        const par = {};
        selectedPersona.custom_parameter1.forEach((p) => {
          par[p.id] = "";
        });
        setParameters1(par);
      }
      if (selectedPersona.custom_parameter2) {
        let parameters = {};
        selectedPersona.custom_parameter2.forEach((p, index) => {
          parameters[index] = p;
        });
        setCustomParameter2(parameters);
      }
      if (selectedPersona.custom_parameter3) {
        let parameters = {};
        selectedPersona.custom_parameter3.forEach((p, index) => {
          parameters[index] = p;
        });
        setCustomParameter3(parameters);
      }
      setValues({
        aura_amount: selectedPersona.group.aura_amount,
      });
      // setCustomParameter1(parameters);
    }
  }, [navigate, selectedPersona]);

  const handleChange = (prop) => (event) => {
    if (prop === "aura_amount" && (isNaN(event) || event <= 0)) {
      return;
    }
    if (prop === "aura_amount") {
      setValues({ ...values, [prop]: event });
      return;
    }
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleParameter2Change = (index) => (event) => {
    setCustomParameter2({ ...customParameter2, [index]: event.target.value });
  };

  const handleParameter3Change = (index) => (event) => {
    setCustomParameter3({ ...customParameter3, [index]: event.target.value });
  };

  const handleUpdate = async () => {
    setShowLoadingModal(true);
    const parameters = selectedPersona.custom_parameter1.map((p) => {
      return {
        ...p,
        value: parameters1[p.id] ? parameters1[p.id] : p.value,
      };
    });
    const persona = {
      // aura_amount: values.aura_amount,
      peerage: values.peerage ?? selectedPersona.peerage,
      custom_parameter1: parameters,
      custom_parameter2: customParameter2,
      custom_parameter3: customParameter3,
    };
    setProgress(20);
    updatePersona(persona);
  };

  const handleParameter1Change = (prop, dataType) => (event) => {
    let isValid = false;
    switch (dataType) {
      case "integer":
        if (
          !isNaN(event.target.value) &&
          Number.isInteger(Number(event.target.value))
        ) {
          isValid = true;
          setParameters1({
            ...parameters1,
            [prop]: Number(event.target.value),
          });
          return;
        }
        break;
      case "float":
        isValid = true;
        break;
      case "string":
        if (typeof event.target.value === "string") {
          isValid = true;
        }
        break;
      default:
        break;
    }
    if (isValid) {
      setParameters1({ ...parameters1, [prop]: event.target.value });
    }
  };

  let loading = false;

  if (!selectedPersona || personaLoading) {
    loading = true;
  }

  return (
    <AppWrapper bgMask={true}>
      <ContentWrapper
        titletopmarign="true"
        title={strings.formatString(contents.title)}
        className="content persona-up-content"
        topSpace={
          <div className="d-flex justify-content-end mb-6-px gap-4">
            <CancelButton
              text={contents.cancel_btn}
              width="173px"
              height="40px"
              onClick={() => navigate(`/personas/${id}`)}
            />
            <Button
              text={contents.update_btn}
              width="173px"
              height="40px"
              onClick={handleUpdate}
              disabled={!loading && isLoading}
            />
          </div>
        }
        marginLeft="12px"
      >
        {loading ? (
          <em>Loading...</em>
        ) : (
          <div className="h-100 mx-36-px my-10-px">
            <section className="row mb-3">
              <div className="col row">
                <p className="font-notosans-jp label-text col-4">
                  {contents.title_name}
                </p>
                <p className="fs-17-px text-gray col-8">
                  {selectedPersona.persona_type_name}
                </p>
              </div>
              <div className="col row">
                <p className="font-notosans-jp label-text col-3">
                  {contents.token_id}
                </p>
                <div className="col-9">
                  <p
                    className="font-ethno-light fs-18-px text-gray"
                    onClick={() => {
                      navigator.clipboard.writeText(
                        selectedPersona.persona_type_no
                      );
                      setShowCopyModal(true);
                    }}
                    style={{ cursor: "pointer" }}
                  >
                    {selectedPersona.persona_type_no}
                    <CopyIcon className="ml-10-px" />
                  </p>
                  <p className="font-notosans-jp fs-14-px text-light-primary">
                    {contents.token_id_secondary}
                  </p>
                </div>
              </div>
            </section>
            <section className="row mb-5 align-items-center">
              <p className="font-notosans-jp label-text col-2">AURA</p>
              <div className="col-4 align-items-center row justify-content-center align-items-center">
                <button
                  className="in-de-btn col-2"
                  onClick={() => {
                    handleChange("aura_amount")(values.aura_amount - 1);
                  }}
                  disabled
                >
                  <DecreaseIcon />
                </button>
                <p className="fs-20-px font-ethno-light text-gray col text-center">
                  {values.aura_amount}
                </p>
                <button
                  className="in-de-btn col-2"
                  onClick={() => {
                    handleChange("aura_amount")(values.aura_amount + 1);
                  }}
                  disabled
                >
                  <IncreaseIcon />
                </button>
              </div>
            </section>
            <section className="mb-5">
              <h3 className="font-notosans-jp label-text mb-4">
                {contents.custom_parameter.label}
              </h3>
              <div className="w-75">
                <div className="row">
                  <div className="col-4"></div>
                  <p className="col-3 label-text fs-16-px text-center">
                    {contents.custom_parameter.current_value}
                  </p>
                  <div className="col-2"></div>
                  <p className="col-3 label-text fs-16-px text-center">
                    {contents.custom_parameter.changed_value}
                  </p>
                </div>
                {selectedPersona.custom_parameter1 &&
                  selectedPersona.custom_parameter1.length > 0 &&
                  parameters1 &&
                  selectedPersona.custom_parameter1.map((p, index) => (
                    <div className="row my-2 align-items-center" key={index}>
                      <p className="col-4 label-text fs-16-px">{p.name}</p>
                      <div className="col-3 d-flex flex-column  align-items-center">
                        <TextInput
                          width="168px"
                          inputClass="text-end px-20-px"
                          inactive
                          disabled
                          value={p.value}
                        />
                      </div>
                      <div className="col-2 d-flex align-items-center justify-content-around">
                        <span className="text-gray fs-17-px">
                          {p.unit ?? "単位"}
                        </span>
                        <ArrowIcon />
                      </div>
                      <div className="col-3 d-flex flex-column  align-items-center">
                        <TextInput
                          width="168px"
                          inputClass="text-end px-20-px text-highlight"
                          value={parameters1[p.id]}
                          onChange={handleParameter1Change(p.id, p.data_type)}
                        />
                      </div>
                    </div>
                  ))}
              </div>
            </section>
            <section className="mb-5">
              <label className="font-notosans-jp label-text mb-4">
                {contents.peerage}
                <span className="fs-14-px text-light-primary ml-50-px">
                  {contents.peerage_secondary}
                </span>
                <span className="fs-14-px text-light-primary ml-50-px">
                  {contents.peerage_third}
                </span>
              </label>
              <div className="d-flex align-items-center">
                <TextInput
                  className="ml-30-px"
                  width="307.92px"
                  inputClass="font-ethno-normal text-gray fs-17-px left-placeholder"
                  inactive
                  value={selectedPersona.peerage}
                  disabled
                />
                <ArrowIcon className="mx-30-px" />
                <SelectInput
                  id="peerage"
                  value={values.peerage}
                  onChange={handleChange("peerage")}
                  options={{
                    Duke: "Duke",
                    Marquess: "Marquess",
                    Count: "Count",
                    Viscount: "Viscount",
                    Baron: "Baron",
                  }}
                  placeholder=""
                  styles={{ width: 307.92 }}
                  className="font-ethno-normal text-gray fs-17-px text-highlight"
                />
              </div>
            </section>
            <section className="mb-5">
              <label className="font-notosans-jp label-text mb-4">
                {contents.parameter_ability}
                <span className="fs-14-px text-light-primary ml-50-px">
                  {contents.parameter_ability_secondary}
                </span>
              </label>
              <div
                className="ml-30-px"
                style={{
                  display: "grid",
                  gridTemplateColumns: "1fr 1fr 1fr",
                  gap: "1.5rem",
                }}
              >
                {customParameter2 &&
                  Object.keys(customParameter2).map((p, index) => (
                    <TextInput
                      key={index}
                      width="300px"
                      inputClass="fs-17-px text-form-control text-center large-placeholder"
                      inactive
                      value={customParameter2[p] ?? ""}
                      onChange={handleParameter2Change(p)}
                      isLeftIcon={true}
                      leftIcon={
                        <span
                          className="parameter-delete-icon"
                          onClick={() => {
                            setCustomParameter2({
                              ...customParameter2,
                              [p]: "",
                            });
                          }}
                        >
                          <DeleteIcon />
                        </span>
                      }
                      placeholder={contents.parameter_placeholder}
                    />
                  ))}
              </div>
              {/* <div className="row ml-17-px mb-4">
              <div className="col-4">
                <TextInput
                  width="300px"
                  inputClass="fs-17-px text-form-control text-center large-placeholder"
                  inactive
                  value="大舞台に強い"
                  onChange={() => {}}
                  isLeftIcon={true}
                  leftIcon={
                    <span className="parameter-delete-icon">
                      <DeleteIcon />
                    </span>
                  }
                  placeholder={contents.parameter_placeholder}
                />
              </div>
              <div className="col-4">
                <TextInput
                  width="300px"
                  inputClass="fs-17-px text-form-control text-center large-placeholder"
                  inactive
                  onChange={() => {}}
                  isLeftIcon={false}
                  leftIcon={
                    <span className="parameter-delete-icon">
                      <DeleteIcon />
                    </span>
                  }
                  placeholder={contents.parameter_placeholder}
                />
              </div>
              <div className="col-4">
                <TextInput
                  width="300px"
                  inputClass="fs-17-px text-form-control text-center large-placeholder"
                  inactive
                  onChange={() => {}}
                  isLeftIcon={false}
                  leftIcon={
                    <span className="parameter-delete-icon">
                      <DeleteIcon />
                    </span>
                  }
                  placeholder={contents.parameter_placeholder}
                />
              </div>
            </div>
            <div className="row ml-17-px mb-4">
              <div className="col-4">
                <TextInput
                  width="300px"
                  inputClass="fs-17-px text-form-control text-center large-placeholder"
                  inactive
                  onChange={() => {}}
                  isLeftIcon={false}
                  leftIcon={
                    <span className="parameter-delete-icon">
                      <DeleteIcon />
                    </span>
                  }
                  placeholder={contents.parameter_placeholder}
                />
              </div>
              <div className="col-4">
                <TextInput
                  width="300px"
                  inputClass="fs-17-px text-form-control text-center large-placeholder"
                  inactive
                  onChange={() => {}}
                  isLeftIcon={false}
                  leftIcon={
                    <span className="parameter-delete-icon">
                      <DeleteIcon />
                    </span>
                  }
                  placeholder={contents.parameter_placeholder}
                />
              </div>
              <div className="col-4">
                <TextInput
                  width="300px"
                  inputClass="fs-17-px text-form-control text-center large-placeholder"
                  inactive
                  onChange={() => {}}
                  isLeftIcon={false}
                  leftIcon={
                    <span className="parameter-delete-icon">
                      <DeleteIcon />
                    </span>
                  }
                  placeholder={contents.parameter_placeholder}
                />
              </div>
            </div> */}
            </section>
            <section className="mb-5">
              <label className="font-notosans-jp label-text mb-4">
                {contents.parameter_title}
                <span className="fs-14-px text-light-primary ml-50-px">
                  {contents.parameter_title_secondary}
                </span>
              </label>
              <div
                className="ml-30-px"
                style={{
                  display: "grid",
                  gridTemplateColumns: "1fr 1fr 1fr",
                  gap: "1.5rem",
                }}
              >
                {customParameter3 &&
                  Object.keys(customParameter3).map((p, index) => (
                    <TextInput
                      key={index}
                      width="300px"
                      inputClass="fs-17-px text-form-control text-center large-placeholder"
                      inactive
                      value={customParameter3[p] ?? ""}
                      onChange={handleParameter3Change(p)}
                      isLeftIcon={true}
                      leftIcon={
                        <span
                          className="parameter-delete-icon"
                          onClick={() => {
                            setCustomParameter3({
                              ...customParameter3,
                              [p]: "",
                            });
                          }}
                        >
                          <DeleteIcon />
                        </span>
                      }
                      placeholder={contents.parameter_placeholder}
                    />
                  ))}
              </div>
              {/* <div className="row ml-17-px mb-4">
              <div className="col-4">
                <TextInput
                  width="300px"
                  inputClass="fs-17-px text-form-control text-center large-placeholder"
                  inactive
                  value="大舞台に強い"
                  onChange={() => {}}
                  isLeftIcon={true}
                  leftIcon={
                    <span className="parameter-delete-icon">
                      <DeleteIcon />
                    </span>
                  }
                  placeholder={contents.parameter_placeholder}
                />
              </div>
              <div className="col-4">
                <TextInput
                  width="300px"
                  inputClass="fs-17-px text-form-control text-center large-placeholder"
                  inactive
                  onChange={() => {}}
                  isLeftIcon={false}
                  leftIcon={
                    <span className="parameter-delete-icon">
                      <DeleteIcon />
                    </span>
                  }
                  placeholder={contents.parameter_placeholder}
                />
              </div>
              <div className="col-4">
                <TextInput
                  width="300px"
                  inputClass="fs-17-px text-form-control text-center large-placeholder"
                  inactive
                  onChange={() => {}}
                  isLeftIcon={false}
                  leftIcon={
                    <span className="parameter-delete-icon">
                      <DeleteIcon />
                    </span>
                  }
                  placeholder={contents.parameter_placeholder}
                />
              </div>
            </div>
            <div className="row ml-17-px mb-4">
              <div className="col-4">
                <TextInput
                  width="300px"
                  inputClass="fs-17-px text-form-control text-center large-placeholder"
                  inactive
                  onChange={() => {}}
                  isLeftIcon={false}
                  leftIcon={
                    <span className="parameter-delete-icon">
                      <DeleteIcon />
                    </span>
                  }
                  placeholder={contents.parameter_placeholder}
                />
              </div>
              <div className="col-4">
                <TextInput
                  width="300px"
                  inputClass="fs-17-px text-form-control text-center large-placeholder"
                  inactive
                  onChange={() => {}}
                  isLeftIcon={false}
                  leftIcon={
                    <span className="parameter-delete-icon">
                      <DeleteIcon />
                    </span>
                  }
                  placeholder={contents.parameter_placeholder}
                />
              </div>
              <div className="col-4">
                <TextInput
                  width="300px"
                  inputClass="fs-17-px text-form-control text-center large-placeholder"
                  inactive
                  onChange={() => {}}
                  isLeftIcon={false}
                  leftIcon={
                    <span className="parameter-delete-icon">
                      <DeleteIcon />
                    </span>
                  }
                  placeholder={contents.parameter_placeholder}
                />
              </div>
            </div> */}
            </section>
          </div>
        )}
        <LoadingModal
          isOpen={showLoadingModal}
          onAction={() => {
            setShowLoadingModal(false);
            setTimeout(() => {
              navigate("/personas");
            }, 500);
          }}
          progress={progress}
        ></LoadingModal>
        <InfoModal
          isOpen={showCopyModal}
          onAction={() => setShowCopyModal(false)}
        >
          クリップボードにコピーしました。
        </InfoModal>
      </ContentWrapper>
    </AppWrapper>
  );
};

export default PersonaUp;
