import AppWrapper from "../../layouts/AppWrapper";
import ContentWrapper from "../../layouts/ContentWrapper";
import { strings, translations } from "../../services/localization";
import { useNavigate, useParams } from "react-router-dom";
import Button, { CancelButton } from "../../components/Button";
import { TextInput } from "../../components/Form";
import {
  BottomLessAbyssIcon,
  CrimsonFlameIcon,
  DropOfHeavenIcon,
  EverGreenTreeIcon,
  GoldenLightIcon,
  PenIcon,
  SolidRockIcon,
  UnrivaledSteelIcon,
  UploadIcon,
} from "../../assets/icons";
import { Switch } from "../../components/Switch";
import { InfoModal } from "../../components/Modal";
import { useEffect, useState } from "react";
import { fetchData } from "../../services/fetch";
import { storeErrorModal } from "../../services/storage";
import { useMutation, useQuery } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { setSelectPersona } from "../../slices/personaReducer";

const elements = [
  "heavenly_dew_element", //drop_of_heaven_element
  "crimson_flame_element", //red_lotus_flame_element
  "golden_light_element",
  "eternal_tree_element", //everlasting_tree_element
  "peerless_steel_element", //warriors_steel_element
  "unfathomable_abyss_element",
  "adamantine_rock_element", //solid_rocks_element
];

const PersonaMarket = () => {
  const contents = translations.personaMarketPreview;
  const navigate = useNavigate();
  const { id } = useParams();
  const dispatch = useDispatch();
  const { selectedPersona } = useSelector((state) => state.personas);
  const [showUploadModal, setShowUploadModal] = useState(false);
  const [values, setValues] = useState({
    eternal_tree_element: "",
    crimson_flame_element: "",
    adamantine_rock_element: "",
    peerless_steel_element: "",
    heavenly_dew_element: "",
    golden_light_element: "",
    unfathomable_abyss_element: "",
    volume: "",
    jpy: "",
    anm: "",
  });
  const [isLoadPersona, setIsLoadPersona] = useState(false);

  const fetchPersona = (id) =>
    fetchData({
      url: `api/persona_type/${id}`,
    });

  const { isLoading: personaLoading } = useQuery(
    ["get-persona", id],
    () => fetchPersona(id),
    {
      onError: (error) => {
        storeErrorModal(error.message);
        if (error.response.status === 404) {
          navigate("/personas");
        }
      },
      onSuccess: (data) => {
        dispatch(setSelectPersona(data.data.persona_type));
      },
      enabled: isLoadPersona,
    }
  );

  const postPublish = async (data) => {
    const response = await fetchData({
      url: `api/publish/${id}`,
      method: "post",
      data: data,
    });
    return response;
  };

  const { mutate: createPublish, isLoading } = useMutation(
    async (data) => postPublish(data),
    {
      onError: async (err) => {
        if (err.response.data.errors) {
          console.log(err.response.data.errors);
        }
        storeErrorModal(err);
      },
      onSuccess: async (data) => {
        console.log(data);
        setShowUploadModal(true);
      },
    }
  );

  const handlePublish = async () => {
    const data = {
      ...values,
      publish_count: values.volume,
      required_jpy_amount: values.jpy,
      required_anima_amount: values.anm,
    };
    createPublish(data);
  };

  const handleChange = (prop) => (event) => {
    if (isNaN(event.target.value)) {
      return;
    }
    if (elements.includes(prop)) {
      let total = elements.reduce((a, c) => a + Number(values[c]), 0);
      total = total - Number(values[prop]) + Number(event.target.value);
      if (total > values.volume) {
        return;
      }
    }
    setValues({ ...values, [prop]: Number(event.target.value) });
  };

  useEffect(() => {
    if (!selectedPersona) {
      setIsLoadPersona(true);
    }
  }, [selectedPersona]);

  let loading = false;

  if (!selectedPersona || personaLoading) {
    loading = true;
  }

  return (
    <AppWrapper bgMask={true}>
      <ContentWrapper
        titletopmarign="true"
        title={strings.formatString(contents.title)}
        className="content persona-market-content"
        topSpace={
          <div className="d-flex justify-content-end mb-6-px gap-4">
            <CancelButton
              text={contents.cancel_btn}
              width="173px"
              height="40px"
              onClick={() => navigate(-1)}
            />
            <Button
              text={contents.public_btn}
              width="173px"
              height="40px"
              onClick={handlePublish}
              isLeftIcon={true}
              leftIcon={<UploadIcon className="mr-10-px op-1" />}
              disabled={isLoading}
            />
          </div>
        }
        marginLeft="12px"
      >
        {loading ? (
          <em>Loading...</em>
        ) : (
          <div className="h-100 mx-36-px my-10-px">
            <div className="row align-items-end mb-5">
              <div className="col-4">
                <h5 className="fs-13-px text-primary">My Square</h5>
                <p className="label-text fs-18-px">
                  {contents.private_marketplace}
                </p>
              </div>
              <p className="col text-gray fs-17-px">ORANGE CODE</p>
            </div>
            {/* <div className="row mb-5">
            <label className="label-text fs-18-px col-5">
              {contents.published_on}
            </label>
            <div className="col">
              <span
                className="font-ethno-light label-text text-gray mr-20-px d-inline-block text-end"
                style={{ width: 50 }}
              >
                OFF
              </span>
              <Switch id="public" />
            </div>
          </div> */}
            <section className="row mb-5 w-85">
              <div className="col-4">
                <label className="label-text fs-18-px mb-3">
                  {contents.sale_volume}
                </label>
                <TextInput
                  width="231px"
                  inputClass="text-center text-form-control"
                  inactive
                  isLeftIcon
                  leftIcon={<PenIcon />}
                  placeholder={contents.sale_volume_placeholder}
                  value={values.volume}
                  onChange={handleChange("volume")}
                />
              </div>
              <div className="col-3">
                <label className="label-text fs-18-px mb-3">
                  {contents.current_sale}
                </label>
                <p className="text-highlight fs-20-px font-ethno-light">
                  / {selectedPersona.publish_persona_token_count}
                </p>
              </div>
              <div className="col">
                <label className="label-text fs-18-px mb-3">
                  {contents.disclosed}
                </label>
                <p className="text-highlight fs-20-px font-ethno-light">
                  / {selectedPersona.unpublish_persona_token_count}
                </p>
              </div>
            </section>
            <section className="mb-5">
              <p className="font-notosans-jp label-text mb-4">
                {contents.element.label}
              </p>
              <div className="row pl-30-px">
                <div className="col-4">
                  <div className="row align-items-center mb-3">
                    <label className="font-notosans-jp label-text fs-17-px col-6 p-0">
                      <DropOfHeavenIcon className="mr-10-px" />
                      天の雫
                    </label>
                    <div className="col-6 d-flex align-items-center p-0">
                      <TextInput
                        width="110px"
                        inputClass="text-center font-ethno-normal fs-16-px text-form-control large-placeholder"
                        inactive
                        placeholder="---"
                        isLeftIcon
                        leftIcon={<PenIcon />}
                        value={values.heavenly_dew_element}
                        onChange={handleChange("heavenly_dew_element")}
                      />
                      <p className="label-text fs-17-px ml-10-px">
                        {selectedPersona.unpublish_heavenly_dew_element}
                      </p>
                    </div>
                  </div>
                  <div className="row align-items-center mb-3">
                    <label className="font-notosans-jp label-text fs-17-px col-6 p-0">
                      <EverGreenTreeIcon className="mr-10-px" />
                      常磐なる樹
                    </label>
                    <div className="col-6 d-flex align-items-center p-0">
                      <TextInput
                        width="110px"
                        inputClass="text-center font-ethno-normal fs-16-px text-form-control large-placeholder"
                        inactive
                        placeholder="---"
                        isLeftIcon
                        leftIcon={<PenIcon />}
                        value={values.eternal_tree_element}
                        onChange={handleChange("eternal_tree_element")}
                      />
                      <p className="label-text fs-17-px ml-10-px">
                        {selectedPersona.unpublish_eternal_tree_element}
                      </p>
                    </div>
                  </div>
                  <div className="row align-items-center mb-3">
                    <label className="font-notosans-jp label-text fs-17-px col-6 p-0">
                      <SolidRockIcon className="mr-10-px" />
                      堅牢な岩
                    </label>
                    <div className="col-6 d-flex align-items-center p-0">
                      <TextInput
                        width="110px"
                        inputClass="text-center font-ethno-normal fs-16-px text-form-control large-placeholder"
                        inactive
                        placeholder="---"
                        isLeftIcon
                        leftIcon={<PenIcon />}
                        value={values.adamantine_rock_element}
                        onChange={handleChange("adamantine_rock_element")}
                      />
                      <p className="label-text fs-17-px ml-10-px">
                        {selectedPersona.unpublish_adamantine_rock_element}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-4">
                  <div className="row align-items-center mb-3">
                    <label className="font-notosans-jp label-text fs-17-px col-6 p-0">
                      <CrimsonFlameIcon className="mr-10-px" />
                      紅蓮の焔
                    </label>
                    <div className="col-6 d-flex align-items-center p-0">
                      <TextInput
                        width="110px"
                        inputClass="text-center font-ethno-normal fs-16-px text-form-control large-placeholder"
                        inactive
                        placeholder="---"
                        isLeftIcon
                        leftIcon={<PenIcon />}
                        value={values.crimson_flame_element}
                        onChange={handleChange("crimson_flame_element")}
                      />
                      <p className="label-text fs-17-px ml-10-px">
                        {selectedPersona.unpublish_crimson_flame_element}
                      </p>
                    </div>
                  </div>
                  <div className="row align-items-center mb-3">
                    <label className="font-notosans-jp label-text fs-17-px col-6 p-0">
                      <UnrivaledSteelIcon className="mr-10-px" />
                      無双の鋼
                    </label>
                    <div className="col-6 d-flex align-items-center p-0">
                      <TextInput
                        width="110px"
                        inputClass="text-center font-ethno-normal fs-16-px text-form-control large-placeholder"
                        inactive
                        placeholder="---"
                        isLeftIcon
                        leftIcon={<PenIcon />}
                        value={values.peerless_steel_element}
                        onChange={handleChange("peerless_steel_element")}
                      />
                      <p className="label-text fs-17-px ml-10-px">
                        {selectedPersona.unpublish_peerless_steel_element}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-4">
                  <div className="row align-items-center mb-3">
                    <label className="font-notosans-jp label-text fs-17-px col-6 p-0">
                      <GoldenLightIcon className="mr-10-px" />
                      黄金の光
                    </label>
                    <div className="col-6 d-flex align-items-center p-0">
                      <TextInput
                        width="110px"
                        inputClass="text-center font-ethno-normal fs-16-px text-form-control large-placeholder"
                        inactive
                        placeholder="---"
                        isLeftIcon
                        leftIcon={<PenIcon />}
                        value={values.golden_light_element}
                        onChange={handleChange("golden_light_element")}
                      />
                      <p className="label-text fs-17-px ml-10-px">
                        {selectedPersona.unpublish_golden_light_element}
                      </p>
                    </div>
                  </div>
                  <div className="row align-items-center mb-3">
                    <label className="font-notosans-jp label-text fs-17-px col-6 p-0">
                      <BottomLessAbyssIcon className="mr-10-px" />
                      底知れぬ深淵
                    </label>
                    <div className="col-6 d-flex align-items-center p-0">
                      <TextInput
                        width="110px"
                        inputClass="text-center font-ethno-normal fs-16-px text-form-control large-placeholder"
                        inactive
                        placeholder="---"
                        isLeftIcon
                        leftIcon={<PenIcon />}
                        value={values.unfathomable_abyss_element}
                        onChange={handleChange("unfathomable_abyss_element")}
                      />
                      <p className="label-text fs-17-px ml-10-px">
                        {selectedPersona.unpublish_unfathomable_abyss_element}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <section className="mb-5">
              <p className="font-notosans-jp label-text mb-4">
                {contents.selling_price.label}
              </p>
              <div className="row">
                <div className="col-4">
                  <label
                    className="label-text fs-16-px mb-3 text-center"
                    style={{ width: 231 }}
                  >
                    {contents.selling_price.jp}
                  </label>
                  <div className="d-flex">
                    <TextInput
                      width="231px"
                      inputClass="text-center text-form-control-value inherit-placeholder"
                      inactive
                      isLeftIcon
                      leftIcon={<PenIcon />}
                      placeholder="---"
                      value={values.jpy}
                      onChange={handleChange("jpy")}
                    />
                    <span className="font-ethno-light text-highlight fs-17-px ml-5-px">
                      JPY
                    </span>
                  </div>
                </div>
                <div className="col-4">
                  <label
                    className="label-text fs-16-px mb-3 text-center"
                    style={{ width: 231 }}
                  >
                    {contents.selling_price.anima}
                  </label>
                  <div className="d-flex">
                    <TextInput
                      width="231px"
                      inputClass="text-center text-form-control-value inherit-placeholder"
                      inactive
                      isLeftIcon
                      leftIcon={<PenIcon />}
                      placeholder="---"
                      value={values.anm}
                      onChange={handleChange("anm")}
                    />
                    <span className="font-ethno-light text-highlight fs-17-px ml-5-px">
                      ANM
                    </span>
                  </div>
                </div>
              </div>
            </section>
            <div className="row mb-5">
              <p className="label-text col-2">{contents.total}</p>
              <p className="font-ethno-light text-highlight fs-16-px col-3">
                {values.volume * values.jpy}
                <span className="fs-14-px ml-5-px">JPY</span>
              </p>
              <p className="font-ethno-light text-highlight fs-16-px col">
                {values.volume * values.anm}
                <span className="fs-14-px ml-5-px">ANM</span>
              </p>
            </div>
          </div>
        )}
        <InfoModal
          isOpen={showUploadModal}
          onAction={() => {
            setShowUploadModal(false);
            setTimeout(() => {
              navigate(-1);
            }, 500);
          }}
        >
          マーケットプレイスに更新データを
          <br /> 公開しました
        </InfoModal>
      </ContentWrapper>
    </AppWrapper>
  );
};

export default PersonaMarket;
